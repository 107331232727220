<template>
  <section class="hero is-fullheight">
    <ModalSlide
      v-if="activeModalEditSlide"
      :active="activeModalEditSlide"
      :slideData="slideSelect"
      :loading="loading"
      :mode="activeModalMode"
      @close-modal="resetModalEdit"
      @set-data-action="updateSlideAction"
      @add-slide-action="addSlideAction"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-6 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="slidesList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
                <div class="is-flex is-justify-content-center is-align-items-center" slot="action" slot-scope="scope">
                  <img class="action_img" src="@/assets/icon/edit_icon_pen.png" alt="Editar" @click="showDetailAction(scope.row)">
                </div>
              </base-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'WebPageMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    ModalSlide: () => import('@/components/WebPage/ModalSlide.vue')
  },
  data () {
    return {
      loading: false,
      columnsData: [
        { id: 'name', header: 'Nombre' },
        { id: 'status', name: 'status', header: 'Estatus', class: 'is-vcentered' },
        { id: 'action', name: 'action', header: 'Acciones', class: 'has-text-centered is-vcentered' }
      ],
      slidesList: [],
      activeModalEditSlide: false,
      slideSelect: {},
      activeModalMode: 'edit'
    }
  },
  methods: {
    ...mapActions(['getSlides', 'updateSlide', 'addSlide']),
    async getSlidesList () {
      const resp = await this.getSlides()
      this.slidesList = resp.slides
      this.loading = false
    },
    openAddSlideModal () {
      this.slideSelect = {} // Clear selected slide
      this.activeModalMode = 'add' // Set mode to 'add'
      this.activeModalEditSlide = true // Open modal
    },
    showDetailAction (event) {
      this.slideSelect = event
      this.activeModalEditSlide = true
    },
    resetModalEdit () {
      this.slideSelect = {}
      this.activeModalEditSlide = false
      this.activeModalMode = 'edit'
    },
    async updateSlideAction (dataUpdate) {
      this.loading = true
      const { success } = await this.updateSlide(dataUpdate)
      if (success) {
        this.resetModalEdit()
        this.getSlidesList()
      }
      this.loading = false
    },
    async addSlideAction (dataAdd) {
      this.loading = true
      const { success } = await this.addSlide(dataAdd)
      if (success) {
        this.resetModalEdit()
        this.getSlidesList()
      }
      this.loading = false
    }
  },
  beforeMount () {
    this.loading = true
    this.getSlidesList()
  }
}
</script>

<style lang="scss" scoped>
  .action_img {
    width: 15px;
    margin: auto 10px;
    cursor: pointer;
  }
</style>
